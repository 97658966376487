export const environment = {
    appVersionUrl: "https://usuarios.gestionatupyme.cl/version.html",
    cognitoConfig: {
      domain: "web-usuarios-gestionatupyme",
      region: "us-east-1",
      appClientId: "2bq9q85p1r456ob1go8tt37ri2", 
      userPoolId: "us-east-1_nGZwFgcXd", 
      baseRedirectURI: 'https://usuarios.gestionatupyme.cl',
      amplifyDomain: "https://web-usuarios-gestionatupyme.auth.us-east-1.amazoncognito.com"
    },
    backend: {
      main: 'https://api.gestionatupyme.cl',
    
      web_usuarios: 'https://9m6o4ren7c.execute-api.us-east-1.amazonaws.com/PROD', //Poner el de PROD
    
      //   lcv: 'https://s1ut8n68o5.execute-api.us-east-1.amazonaws.com/DEV',
      // webClientes: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV',
  
      // authorization: '',
      // usuarios: 'https://z4nt9vwayh.execute-api.us-east-1.amazonaws.com/DEV/userAccess',
      // pdfGenerator: 'https://4lz8y9h6md.execute-api.us-east-1.amazonaws.com/DEV',
      // organizations:'https://z4nt9vwayh.execute-api.us-east-1.amazonaws.com/DEV/configurateOrganization',
      // quicksightPanelRiesgo:'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/553800993060/dashboards/9f9edc26-4a33-450d-8b48-6374b1baf315?directory_alias=gestionatupymecl',
      // report: {
      //   servicio: 'rb',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      // empresaEnUnDia: {
      //   servicio: 'eeud',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      // boletinConcursal: {
      //   servicio: 'bc',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      // poderJudicial: {
      //   servicio: 'pj',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      // SolicitudCredito: {
      //   servicio: 'sc',
      //   url: 'https://kcefx0ca1f.execute-api.us-east-1.amazonaws.com/DEV/'
      //   //url: 'https://ii2prkjexh.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      // SolicitudCreditoWorkflow: {
      //   servicio: 'sc',
      //   url: 'https://ii2prkjexh.execute-api.us-east-1.amazonaws.com/DEV/' 
      // },
      calculadoraUF: {
        servicio: 'cuf',
        url: 'https://b1i1ky38d6.execute-api.us-east-1.amazonaws.com/DEV/obtenerIndicadores'
      },
      // SbifLeasing: {
      //   servicio: 'dls',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      // carpetaTributaria: {
      //   servicio: 'cte',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      // vehiculos: {
      //   servicio: 've',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      // bienesRaices: {
      //   servicio: 'bbrr',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      // formularioF29: {
      //   servicio: 'f29',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      // formularioF22: {
      //   servicio: 'f22',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      // compraVenta: {
      //   servicio: 'lcv',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      // MallaSocietaria: {
      //   servicio: 'ms',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      secretInteraction: {
        servicio: 'css',
        url: 'https://api.gestionatupyme.cl/SecretInteraction'
      },
      // camposPersonalizados: {
      //   servicio: 'cp',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      // tesoreria: {
      //   servicio: 'te',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      // balanceManual: {
      //   servicio: 'bl',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      // preBalanceManual: {
      //   servicio: 'pb',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // },
      // mercadoPublico: {
      //   servicio: 'mp',
      //   url: 'https://790xiu94re.execute-api.us-east-1.amazonaws.com/DEV/'
      // }
    },
    production: false,
    inactivity: {
      /**
      * @description 
      * La biblioteca está esperando a que un usuario esté inactivo durante el tiempo indicado
      * (2 minutos)
      */
      initInactivity: 900,
      /**
      * @description 
      * Si el usuario no detuvo el temporizador, 
      * el tiempo se agotó después de 3600 segundos (1 hora) y onTimeout() se activa
      */
      timeoutInactivity: 10,
      /**
      * @description 
      * Realizar alguna acción periódicamente cada n minutos en el ciclo de vida del 
      * temporizador (desde el inicio del temporizador hasta el tiempo de espera).
      * Por ejemplo, cada (2 minutos) se mostrara la modal de inactividad
      */
      respawnInactivity: 10,
      /**
      * @description 
      * El tiempo que durara la modal de inactividad activa antes de cerrarse en caso de que
      * el usuario no realice ninguna accion en este caso (15 segundos) se cerrara la sesion y redirigira al login,
      * si el usuario presiona SI la inactividad vuelve a 0 y solo se volvera a activar cuando
      * el usuario vuelva a estar inactivo en este caso por @param initInactivity, en caso de
      * presionar NO cerrara la modal y cerrara la sesion y redirigira al login 
      */
      countdown: 15
    },
    /**
     * @description
     * Tiempo en que el observador del token verificara si esta activo
     */
    tokenObs: 1000,
    /**
     * @description
     * Cantidad de registros a mostrar al principio en las tablas
     */
    initItemPerPage: 5,
    /**
     * @description
     * Opciones que tendra cada tabla para mostrar X cantidad de registro
     */
    itemPerPageOptions: [5, 10, 25, 50, 100],
    waitTimes: {
      /**
       * @description
       * Tiempo que duraran las alertas en pantalla
       */
      alerts: 10000,
      /**
       * @description
       * Tiempo de espera para descargar PDF
       */
      downloadPdf: 5000,
      /**
       * @description
       * Tiempo de espera para obtener reporte en un dia
       */
      obtenerReporte: 500,
      /**
       * @description
       * Cantidad de intentos para obtener reporte en un dia
       */
      intentosObtenerReporte: 10
    }
  };
  